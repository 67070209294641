import { gql } from '@apollo/client';

const insertUserMutation = gql`
    mutation insertUser($email: String!) {
        insert_user_one(object: { email: $email }) {
            email
        }
    }
`;

export default insertUserMutation;
