import { getTZDate } from '@/lib/getDates';
import { AreaChart, getFilteredChartTooltipPayload } from '@mantine/charts';
import { Center, Grid, Group, Paper, ScrollArea, Space, Table, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { IconCheck, IconClock, IconServerCog, IconTool, IconX } from '@tabler/icons-react';

import { NoteCard } from '../..';

const ChartTooltip = ({ label, payload }) => {
    return (
        <Paper px="lg" py="lg" shadow="md">
            <Group gap={4}>
                <ThemeIcon variant="transparent" color="dark" size="xs">
                    <IconClock />
                </ThemeIcon>
                <Text fz="xs">{label}</Text>
            </Group>
            {getFilteredChartTooltipPayload(payload).map((item) => (
                <Text key={item.name}>
                    <b>{item.name}:</b> {item.value}
                </Text>
            ))}
        </Paper>
    );
};

const PlantInfoModalContent = ({ displayMeasures, plantData, activePlantNotes }) => {
    return (
        <Grid pt={0} p="md">
            <Grid.Col span={{ base: 12, sm: 8 }}>
                {displayMeasures ? (
                    <>
                        <Text ta="center" fz="sm">
                            Potenza istantanea
                        </Text>
                        <Text ta="center" fz="xs" c="dimmed">
                            Media per fascia oraria
                        </Text>
                        <AreaChart
                            mt="sm"
                            h={260}
                            data={displayMeasures}
                            tooltipAnimationDuration={200}
                            tooltipProps={{
                                content: ({ label, payload }) => <ChartTooltip label={label} payload={payload} />,
                            }}
                            yAxisProps={{
                                domain: [0, Math.ceil(Math.max(...displayMeasures.map((data) => parseFloat(data.KW))))],
                            }}
                            yAxisLabel="KW"
                            dataKey="date"
                            series={[{ name: 'KW', color: 'indigo.6' }]}
                            strokeWidth={3}
                        />
                    </>
                ) : (
                    <Center mih={260}>
                        <Text>Dati non disponibili</Text>
                    </Center>
                )}
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 4 }}>
                <Text ta="center">Potenza impianto</Text>
                <Text fz="lg" ta="center" fw={600}>
                    {plantData.plantPower} KW
                </Text>
                <Space h="md" />
                {activePlantNotes.length > 0 ? (
                    <ScrollArea.Autosize pl="md" pr="md" mah={240}>
                        {activePlantNotes.map((note) => (
                            <NoteCard key={note.id} note={note} canDelete={false} />
                        ))}
                    </ScrollArea.Autosize>
                ) : (
                    <Text c="dimmed" size="sm" ta="center">
                        Nessuna nota attiva
                    </Text>
                )}
            </Grid.Col>
            <Grid.Col>
                <Table striped highlightOnHover>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th w="33%" ta="center">
                                Irraggiamento
                            </Table.Th>
                            <Table.Th w="33%" ta="center">
                                Potenza istantanea
                            </Table.Th>
                            <Table.Th w="33%" ta="center">
                                Ultima lettura dati
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td fz="sm" ta="center">
                                {plantData.measures.length > 0 && plantData.measures[0].irradiance !== null
                                    ? plantData.measures[0].irradiance.toFixed(2)
                                    : '-'}{' '}
                                W/m
                                <sup>2</sup>
                            </Table.Td>
                            <Table.Td fz="sm" ta="center">
                                {plantData.measures.length > 0 ? (plantData.measures[0].power / 1000).toFixed(2) : '-'}{' '}
                                KW
                            </Table.Td>
                            <Table.Td fz="sm" ta="center">
                                {plantData.measures.length > 0
                                    ? getTZDate(new Date(plantData.measures[0].date)).toLocaleString()
                                    : '-'}
                            </Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </Table>
                <Table striped highlightOnHover>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th w="33%" ta="center">
                                Temperatura
                            </Table.Th>
                            <Table.Th w="33%" ta="center">
                                Performance Ratio
                            </Table.Th>
                            <Table.Th w="33%" ta="center">
                                Frequenza di aggiornamento
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td fz="sm" ta="center">
                                {plantData.measures.length > 0 && plantData.measures[0].temperature !== null
                                    ? plantData.measures[0].temperature.toFixed(2)
                                    : '-'}{' '}
                                °C
                            </Table.Td>
                            <Table.Td fz="sm" ta="center">
                                {plantData.targetPerformanceRatio} %
                            </Table.Td>
                            <Table.Td fz="sm" ta="center">
                                {plantData.refreshInterval} min
                            </Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </Table>
                <Table striped highlightOnHover>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th w="50%" ta="center">
                                Solarimetro
                            </Table.Th>
                            <Table.Th w="50%" ta="center">
                                Manutenzione
                            </Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td>
                                <Group justify="center">
                                    <ThemeIcon size="xs" color={plantData.solarimeter ? 'teal' : 'red'}>
                                        {plantData.solarimeter ? <IconCheck /> : <IconX />}
                                    </ThemeIcon>
                                </Group>
                            </Table.Td>
                            <Table.Td>
                                <Group justify="center">
                                    <Tooltip
                                        withArrow
                                        label={
                                            plantData.repairing
                                                ? 'In riparazione'
                                                : plantData.maintenance
                                                ? 'In manutenzione'
                                                : 'Non in manutenzione'
                                        }
                                    >
                                        <ThemeIcon
                                            size="xs"
                                            color={
                                                plantData.repairing
                                                    ? 'orange'
                                                    : plantData.maintenance
                                                    ? 'yellow'
                                                    : 'teal'
                                            }
                                        >
                                            {plantData.repairing ? (
                                                <IconTool />
                                            ) : plantData.maintenance ? (
                                                <IconServerCog />
                                            ) : (
                                                <IconCheck />
                                            )}
                                        </ThemeIcon>
                                    </Tooltip>
                                </Group>
                            </Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </Table>
            </Grid.Col>
            <Grid.Col>
                <Text size="sm" ta="center" fw={700}>
                    Dettagli
                </Text>
                <Text fz="xs" ta="center">
                    {plantData.details || '-'}
                </Text>
            </Grid.Col>
        </Grid>
    );
};

export default PlantInfoModalContent;
