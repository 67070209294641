import { gql } from '@apollo/client';

const updatePlantNoteMutation = gql`
    mutation updatePlantNote($id: Int!, $active: Boolean) {
        update_plant_note_by_pk(pk_columns: { id: $id }, _set: { active: $active }) {
            id
        }
    }
`;

export default updatePlantNoteMutation;
