import { create } from 'zustand';

const usePlants = create((set) => ({
    plants: [],
    scintillaPlants: [],
    powerMeasuresData: {},
    setPlants: (plants) => set((state) => ({ ...state, plants })),
    setScintillaPlants: (scintillaPlants) => set((state) => ({ ...state, scintillaPlants })),
    setPowerMeasuresData: (powerMeasuresData) => set((state) => ({ ...state, powerMeasuresData })),
}));

export default usePlants;
