import { Stepper } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import deepmerge from 'deepmerge';
import { useState } from 'react';

import { StandardLoadingOverlay } from '../loaders';
import { PlantDetailsStep, PlantSelectStep } from '../steps';

const AddPlantModalContent = () => {
    const [formData, setFormData] = useState({});
    const [active, setActive] = useState(0);
    const [highestStepVisited, setHighestStepVisited] = useState(active);

    const [overlay, { toggle: toggleOverlay }] = useDisclosure(false);

    const handleStepChange = (nextStep, newFormData) => {
        let maxSteps = 2;

        const isOutOfBounds = nextStep > maxSteps || nextStep < 0;
        if (isOutOfBounds) {
            return;
        }

        setActive(nextStep);
        setHighestStepVisited((hSC) => Math.max(hSC, nextStep));
        let formDataOld = _.cloneDeep(formData);
        setFormData(deepmerge(formDataOld, newFormData));
    };

    const shouldAllowSelectStep = (step) => highestStepVisited >= step && active !== step;

    return (
        <>
            <StandardLoadingOverlay visible={overlay} />
            <Stepper iconSize={24} active={active} onStepClick={setActive} breakpoint="sm">
                <Stepper.Step allowStepSelect={shouldAllowSelectStep(0)} label="Seleziona impianto">
                    <PlantSelectStep formData={formData} completeStepHandler={handleStepChange} />
                </Stepper.Step>
                <Stepper.Step allowStepSelect={shouldAllowSelectStep(1)} label="Dettagli tecnici">
                    <PlantDetailsStep toggleOverlay={toggleOverlay} formData={formData} />
                </Stepper.Step>
            </Stepper>
        </>
    );
};

export default AddPlantModalContent;
