import { gql } from '@apollo/client';

const downloadReportMutation = gql`
    mutation downloadReport($plantId: Int!, $year: Int!) {
        downloadReport(plant_id: $plantId, year: $year) {
            data
        }
    }
`;

export default downloadReportMutation;
