import { deletePlantNoteMutation, updatePlantNoteMutation } from '@/lib/apollo/mutations';
import { getTZDate } from '@/lib/getDates';
import { useMutation } from '@apollo/client';
import { ActionIcon, Box, Card, Group, Switch, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconUser } from '@tabler/icons-react';

import { StandardLoadingOverlay } from './loaders';

const NoteCard = ({ note, canDelete }) => {
    const [overlay, { toggle: toggleOverlay }] = useDisclosure(false);
    const [deletePlantNote] = useMutation(deletePlantNoteMutation, {
        refetchQueries: ['getPlantNotes', 'getPlants'],
        variables: { id: note.id },
        onCompleted: () => {
            notifications.show({
                title: 'Operazione completata',
                message: 'Nota eliminata correttamente',
                color: 'teal',
            });
            toggleOverlay();
        },
    });
    const [updatePlantNote] = useMutation(updatePlantNoteMutation, {
        refetchQueries: ['getPlantNotes', 'getPlants'],
        onCompleted: () => {
            notifications.show({
                title: 'Operazione completata',
                message: 'Nota aggiornata correttamente',
                color: 'teal',
            });
            toggleOverlay();
        },
    });

    return (
        <Card withBorder mb="sm" shadow="sm">
            <Card.Section bg="rgba(0,0,0,0.1)">
                <Group pl="md" pr="md" pt={2} pb={2} justify="space-between">
                    <Group gap="xs">
                        {canDelete && (
                            <Tooltip withArrow label={<Text fz="sm">Elimina</Text>}>
                                <ActionIcon
                                    onClick={() => {
                                        toggleOverlay();
                                        deletePlantNote();
                                    }}
                                    size={10}
                                    color="red"
                                    radius="xl"
                                />
                            </Tooltip>
                        )}
                        <Text fz="xs">Nota del {getTZDate(new Date(note.createdDate)).toLocaleString()}</Text>
                        <Tooltip
                            withArrow
                            label={<Text fz="sm">{note.active ? 'Attiva' : 'Non attiva'}</Text>}
                            refProp="rootRef"
                        >
                            <Switch
                                size="xs"
                                checked={note.active}
                                onChange={(e) => {
                                    toggleOverlay();
                                    updatePlantNote({ variables: { id: note.id, active: e.target.checked } });
                                }}
                            />
                        </Tooltip>
                    </Group>
                    <Group gap={2}>
                        <Tooltip withArrow label={<Text fz="sm">{note.email}</Text>}>
                            <ThemeIcon color="dark" size="xs" variant="transparent">
                                <IconUser />
                            </ThemeIcon>
                        </Tooltip>
                    </Group>
                </Group>
            </Card.Section>
            <StandardLoadingOverlay visible={overlay} />
            <Box pt="xs" pb="xs" mih={40}>
                <Text fz="xs">{note.content}</Text>
            </Box>
        </Card>
    );
};

export default NoteCard;
