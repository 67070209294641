import { gql } from '@apollo/client';

const getApiTypesQuery = gql`
    query getApiTypes {
        apiType: api_type {
            label: name
            value: id
        }
    }
`;

export default getApiTypesQuery;
