import { gql } from '@apollo/client';

const getPlantNotesQuery = gql`
    query getPlantNotes($plantId: Int!) {
        plantNote: plant_note(where: { plant_id: { _eq: $plantId } }, order_by: { created_date: desc }) {
            id
            active
            createdDate: created_date
            email
            content
        }
    }
`;

export default getPlantNotesQuery;
