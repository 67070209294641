export const getTZDate = (date) => {
    let dt;
    if (date) {
        dt = new Date(date);
        const currentDate = new Date();
        const offset = (currentDate.getHours() - currentDate.getUTCHours()) * 3600000;
        dt.setTime(dt.getTime() + offset);
    }
    return dt;
};
