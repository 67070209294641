import { insertPlantNoteMutation } from '@/lib/apollo/mutations';
import { useMutation } from '@apollo/client';
import { Box, Button, Grid, Group, ScrollArea, Stack, Switch, Text, Textarea } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { useSession } from 'next-auth/react';
import { useState } from 'react';

import { NoteCard } from '../..';
import { StandardLoadingOverlay } from '../../loaders';

const PlantNoteModalContent = ({ plantId, plantName, plantNotes }) => {
    const { data: session } = useSession();
    const [overlay, { toggle: toggleOverlay }] = useDisclosure(false);
    const [insertPlantNote] = useMutation(insertPlantNoteMutation, {
        refetchQueries: ['getPlants', 'getPlantNotes'],
        onCompleted: () => {
            notifications.show({
                title: 'Operazione completata',
                message: 'Nota aggiunta correttamente',
                color: 'teal',
            });
            form.setValues({ content: '' });
            toggleOverlay();
        },
        onError: () => {
            notifications.show({
                title: 'Operazione fallita',
                message: 'Si è verificato un errore',
                color: 'red',
            });
            toggleOverlay();
        },
    });

    const [checked, setChecked] = useState(true);

    const form = useForm({
        validate: { content: isNotEmpty('Inserisci una nota.') },
    });

    return (
        <>
            <StandardLoadingOverlay visible={overlay} />
            {Boolean(plantNotes) && (
                <Grid pt={0} p="md">
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        <Box
                            component="form"
                            onSubmit={form.onSubmit((values) => {
                                toggleOverlay();
                                insertPlantNote({
                                    variables: {
                                        content: values.content,
                                        email: session.data.email,
                                        active: checked,
                                        plantId,
                                    },
                                });
                            })}
                        >
                            <Stack>
                                <Textarea
                                    autosize
                                    withAsterisk
                                    minRows={10}
                                    maxRows={12}
                                    label="Nota"
                                    placeholder="Inserisci la tua nota"
                                    description={`Aggiungi una nota per l'impianto ${plantName}`}
                                    {...form.getInputProps('content')}
                                />
                                <Switch
                                    style={{ cursor: 'pointer' }}
                                    checked={checked}
                                    label="Nota attiva"
                                    onChange={(e) => setChecked(e.target.checked)}
                                />
                                <Group>
                                    <Button leftSection={<IconDeviceFloppy />} size="compact-md" type="submit">
                                        Salva
                                    </Button>
                                </Group>
                            </Stack>
                        </Box>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6 }}>
                        {plantNotes.length > 0 ? (
                            <ScrollArea.Autosize mah={600} mx="auto">
                                {plantNotes.map((note) => (
                                    <NoteCard key={note.id} note={note} canDelete={true} />
                                ))}
                            </ScrollArea.Autosize>
                        ) : (
                            <Text ta="center" c="dimmed">
                                Nessuna nota inserita
                            </Text>
                        )}
                    </Grid.Col>
                </Grid>
            )}
        </>
    );
};

export default PlantNoteModalContent;
