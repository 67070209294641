import { insertPlantMutation } from '@/lib/apollo/mutations';
import { useMutation } from '@apollo/client';
import { isNotEmpty, useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

import { PlantDetailsForm } from '../forms';

const PlantDetailsStep = ({ formData, toggleOverlay }) => {
    const [activeChecked, setActiveChecked] = useState(true);
    const [maintenanceChecked, setMaintenanceChecked] = useState(false);
    const [repairingChecked, setRepairingChecked] = useState(false);
    const [sendAlertNotificationChecked, setSendAlertNotificationChecked] = useState(true);

    const form = useForm({
        initialValues: {
            name: formData.selectedPlant?.name || '',
            url: formData.url || '',
            apiType: formData.apiType || '1',
            targetPerformanceRatio: formData.targetPerformanceRatio || '70',
            temperatureCoefficient: formData.temperatureCoefficient || '0',
            tare: formData.tare || '0',
            solarimeter: formData.solarimeter?.toString() || '1',
            refreshInterval: formData.refreshInterval || '5',
            details: formData.details || '',
            plantPower: formData.selectedPlant?.plantPower || '0.01',
        },
        validate: {
            name: isNotEmpty('Il nome impianto non può essere vuoto.'),
            url: isNotEmpty("L'URL impianto non può essere vuoto."),
            plantPower: isNotEmpty("La potenza dell'impianto non può essere vuota."),
        },
    });

    const [insertPlant] = useMutation(insertPlantMutation, {
        refetchQueries: ['getPlants'],
        onCompleted: () => {
            notifications.show({
                title: 'Operazione completata',
                message: 'Impianto aggiunto correttamente',
                color: 'teal',
            });
            toggleOverlay();
            modals.closeAll();
        },
    });

    return (
        <PlantDetailsForm
            form={form}
            onSubmit={form.onSubmit((values) => {
                toggleOverlay();
                insertPlant({
                    variables: {
                        extId: parseInt(formData.selectedPlant?.id),
                        name: values.name,
                        url: values.url,
                        apiTypeId: parseInt(values.apiType),
                        targetPerformanceRatio: values.targetPerformanceRatio || 0,
                        temperatureCoefficient: values.temperatureCoefficient || 0,
                        tare: values.tare || 0,
                        solarimeter: Boolean(parseInt(values.solarimeter)),
                        refreshInterval: values.refreshInterval || 5,
                        details: values.details,
                        active: activeChecked,
                        maintenance: maintenanceChecked,
                        repairing: repairingChecked,
                        sendAlertNotification: sendAlertNotificationChecked,
                        plantPower: values.plantPower,
                    },
                });
            })}
            activeChecked={activeChecked}
            maintenanceChecked={maintenanceChecked}
            repairingChecked={repairingChecked}
            sendAlertNotificationChecked={sendAlertNotificationChecked}
            setActiveChecked={setActiveChecked}
            setMaintenanceChecked={setMaintenanceChecked}
            setRepairingChecked={setRepairingChecked}
            setSendAlertNotificationChecked={setSendAlertNotificationChecked}
        />
    );
};

export default PlantDetailsStep;
