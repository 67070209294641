import { gql } from '@apollo/client';

const getScintillaPlantsQuery = gql`
    query getScintillaPlants {
        plant: scintilla_centrale {
            id
            name: nome_centrale
            kwimp
        }
    }
`;

export default getScintillaPlantsQuery;
