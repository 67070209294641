import { updatePlantMutation } from '@/lib/apollo/mutations';
import { useMutation } from '@apollo/client';
import { Modal, Text } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';

import { PlantDetailsForm } from '../forms';
import { StandardLoadingOverlay } from '../loaders';

const EditPlantModal = ({ data, opened, close }) => {
    const [activeChecked, setActiveChecked] = useState(data.active);
    const [maintenanceChecked, setMaintenanceChecked] = useState(data.maintenance);
    const [repairingChecked, setRepairingChecked] = useState(data.repairing);
    const [sendAlertNotificationChecked, setSendAlertNotificationChecked] = useState(data.sendAlertNotification);
    const [overlay, { toggle: toggleOverlay }] = useDisclosure(false);

    const [updatePlant] = useMutation(updatePlantMutation, {
        refetchQueries: ['getPlants'],
        onCompleted: () => {
            notifications.show({
                title: 'Operazione completata',
                message: 'Impianto aggiornato correttamente',
                color: 'teal',
            });
            toggleOverlay();
            close();
        },
    });

    const form = useForm({
        initialValues: {
            name: data.name,
            url: data.url,
            apiType: data.apiType.id.toString(),
            plantPower: data.plantPower,
            targetPerformanceRatio: data.targetPerformanceRatio,
            temperatureCoefficient: data.temperatureCoefficient,
            tare: data.tare,
            solarimeter: data.solarimeter ? '1' : '0',
            refreshInterval: data.refreshInterval,
            details: data.details,
        },
        validate: {
            name: isNotEmpty('Il nome impianto non può essere vuoto.'),
            url: isNotEmpty("L'URL impianto non può essere vuoto."),
            plantPower: isNotEmpty("La potenza dell'impianto non può essere vuota."),
        },
    });

    return (
        <Modal
            opened={opened}
            onClose={close}
            withinPortal
            zIndex={250}
            title={
                <Text pl="md" fz="xl" fw={600}>
                    Modifica impianto - {data.name}
                </Text>
            }
            overlayProps={{
                backgroundOpacity: 0.4,
                blur: 1.3,
            }}
            size="xl"
        >
            <StandardLoadingOverlay visible={overlay} />
            <PlantDetailsForm
                form={form}
                onSubmit={form.onSubmit((values) => {
                    toggleOverlay();
                    updatePlant({
                        variables: {
                            id: data.id,
                            name: values.name,
                            url: values.url,
                            apiTypeId: parseInt(values.apiType),
                            targetPerformanceRatio: values.targetPerformanceRatio || 0,
                            temperatureCoefficient: values.temperatureCoefficient || 0,
                            tare: values.tare || 0,
                            solarimeter: Boolean(parseInt(values.solarimeter)),
                            refreshInterval: values.refreshInterval || 5,
                            details: values.details,
                            active: activeChecked,
                            maintenance: maintenanceChecked,
                            repairing: repairingChecked,
                            plantPower: values.plantPower,
                            sendAlertNotification: sendAlertNotificationChecked,
                        },
                    });
                })}
                activeChecked={activeChecked}
                maintenanceChecked={maintenanceChecked}
                repairingChecked={repairingChecked}
                sendAlertNotificationChecked={sendAlertNotificationChecked}
                setActiveChecked={setActiveChecked}
                setMaintenanceChecked={setMaintenanceChecked}
                setRepairingChecked={setRepairingChecked}
                setSendAlertNotificationChecked={setSendAlertNotificationChecked}
            />
        </Modal>
    );
};

export default EditPlantModal;
