import { gql } from '@apollo/client';

const getPowerMeasuresQuery = gql`
    query getMeasures($date: timestamp!) {
        powerMeasure: measure(where: { date: { _gte: $date } }, order_by: { date: asc }) {
            date
            power
            plantId: plant_id
        }
    }
`;

export default getPowerMeasuresQuery;
