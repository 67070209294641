import { gql } from '@apollo/client';

const deletePlantNoteMutation = gql`
    mutation deletePlantNote($id: Int!) {
        delete_plant_note_by_pk(id: $id) {
            id
        }
    }
`;

export default deletePlantNoteMutation;
