import { gql } from '@apollo/client';

const updatePlantMutation = gql`
    mutation updatePlant(
        $id: Int!
        $active: Boolean!
        $apiTypeId: Int!
        $details: String
        $maintenance: Boolean!
        $repairing: Boolean!
        $name: String!
        $refreshInterval: Int!
        $solarimeter: Boolean!
        $tare: float8!
        $targetPerformanceRatio: float8!
        $temperatureCoefficient: float8!
        $sendAlertNotification: Boolean!
        $url: String!
        $plantPower: float8!
    ) {
        update_plant_by_pk(
            pk_columns: { id: $id }
            _set: {
                active: $active
                api_type_id: $apiTypeId
                details: $details
                maintenance: $maintenance
                repairing: $repairing
                name: $name
                plant_power: $plantPower
                refresh_interval: $refreshInterval
                solarimeter: $solarimeter
                tare: $tare
                target_performance_ratio: $targetPerformanceRatio
                temperature_coefficient: $temperatureCoefficient
                send_alert_notification: $sendAlertNotification
                url: $url
            }
        ) {
            id
        }
    }
`;

export default updatePlantMutation;
