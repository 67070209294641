'use client';

//import { firebaseAuth } from '@/lib/firebase';
import { Anchor, Box, Button, Card, Center, Stack, Text, TextInput, Title } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { redirect } from 'next/navigation';

const PasswordResetForm = ({ callback }) => {
    const { status } = useSession();
    status === 'authenticated' && redirect('/');
    const form = useForm({
        initialValues: {
            email: '',
        },
        validate: {
            email: isNotEmpty('Inserisci il tuo indirizzo mail'),
        },
    });

    return (
        <Center>
            <Card miw={400} shadow="lg" p="xl">
                <Box
                    p="lg"
                    component="form"
                    onSubmit={form.onSubmit(async (values) => {
                        await callback(values.email)
                            .then(() =>
                                notifications.show({
                                    title: 'Operazione completata',
                                    message: `Email correttamente inviata all'indirizzo ${values.email}`,
                                    color: 'teal',
                                })
                            )
                            .catch(() =>
                                notifications.show({
                                    title: 'Errore',
                                    message: "Qualcosa è andato storto nell'invio della Mail",
                                    color: 'red',
                                })
                            );
                    })}
                >
                    <Stack>
                        <Title ta="center" order={2}>
                            Enami DataLogger
                        </Title>
                        <Text ta="center">Reimposta password</Text>
                        <Text ta="center" fz="xs">
                            Controlla la tua casella email, troverai un link per reimpostare la tua password
                        </Text>
                        <TextInput
                            label="Email"
                            placeholder="Il tuo indirizzo email"
                            {...form.getInputProps('email')}
                        />
                        <Anchor href="/login" component={Link} fz="sm" ta="center">
                            Oppure Accedi
                        </Anchor>
                        <Button type="submit">Reimposta password</Button>
                    </Stack>
                </Box>
            </Card>
        </Center>
    );
};

export default PasswordResetForm;
