import { gql } from '@apollo/client';

const insertPlantNoteMutation = gql`
    mutation insertPlantNote($active: Boolean!, $content: String!, $email: String!, $plantId: Int!) {
        insert_plant_note_one(object: { active: $active, content: $content, email: $email, plant_id: $plantId }) {
            id
        }
    }
`;

export default insertPlantNoteMutation;
