import { downloadReportMutation } from '@/lib/apollo/mutations';
import { useMutation } from '@apollo/client';
import { Box, Button, Grid, Modal, Text } from '@mantine/core';
import { YearPickerInput } from '@mantine/dates';
import { isNotEmpty, useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { IconDownload } from '@tabler/icons-react';

const b64ToBlob = (b64Data, sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    return blob;
};

const ReportYearSelectModal = ({ plantId, opened, close }) => {
    const [downloadReport, { loading }] = useMutation(downloadReportMutation, {
        onCompleted: ({ downloadReport }) => {
            const blob = b64ToBlob(downloadReport.data);
            const url = URL.createObjectURL(blob);
            window.open(url);
            close();
        },
        onError: () => {
            notifications.show({
                title: 'Errore',
                message: 'Impossibile scaricare il report',
                color: 'red',
            });
        },
    });

    const form = useForm({
        initialValues: {
            year: new Date(),
        },
        validate: {
            year: isNotEmpty('Inserisci un anno per il download del report'),
        },
    });

    return (
        <Modal
            opened={opened}
            onClose={close}
            withinPortal
            zIndex={250}
            title={
                <Text pl="md" fz="xl" fw={600}>
                    Scarica Report
                </Text>
            }
            overlayProps={{
                backgroundOpacity: 0.2,
                blur: 1.3,
            }}
            size="xl"
        >
            <Box
                p="md"
                component="form"
                onSubmit={form.onSubmit((values) =>
                    downloadReport({ variables: { plantId, year: values.year.getFullYear() } })
                )}
            >
                <Grid>
                    <Grid.Col>
                        <YearPickerInput
                            label="Anno di Report"
                            placeholder="Seleziona l'anno di riferimento per il Report"
                            withAsterisk
                            {...form.getInputProps('year')}
                        />
                    </Grid.Col>
                </Grid>
                <Button disabled={loading} type="submit" mt="md" leftSection={<IconDownload />}>
                    Scarica
                </Button>
            </Box>
        </Modal>
    );
};

export default ReportYearSelectModal;
