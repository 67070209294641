import { create } from 'zustand';

const today = new Date().toISOString().split('T')[0];

const useSun = create((set) => ({
    sunrise: new Date(`${today} 6:00:00 AM`),
    sunrise: new Date(`${today} 7:00:00 PM`),
    setSunrise: (sunrise) => set((state) => ({ ...state, sunrise })),
    setSunset: (sunset) => set((state) => ({ ...state, sunset })),
}));

export default useSun;
