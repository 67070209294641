import { gql } from '@apollo/client';

const deletePlantMutation = gql`
    mutation deletePlant($id: Int!) {
        delete_plant_by_pk(id: $id) {
            id
        }
    }
`;

export default deletePlantMutation;
