import { deletePlantMutation, downloadReportMutation } from '@/lib/apollo/mutations';
import { getPlantNotesQuery } from '@/lib/apollo/queries';
import { usePlants } from '@/lib/store';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Group, Tabs, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { IconDownload, IconEdit, IconTrash } from '@tabler/icons-react';

import { EditPlantModal, ReportYearSelectModal } from '.';
import { StandardLoadingOverlay } from '../loaders';
import { PlantInfoModalContent, PlantNoteModalContent } from './components';

const PlantModalContent = ({ powerMeasuresData, plantId }) => {
    const [deletePlant] = useMutation(deletePlantMutation, {
        refetchQueries: ['getPlants'],
        onCompleted: () => {
            notifications.show({
                title: 'Operazione completata',
                message: 'Impianto rimosso correttamente',
                color: 'teal',
            });
        },
    });

    const displayMeasures = powerMeasuresData?.map((measure) => {
        return {
            date: (measure.date.length === 1 ? `0${measure.date}` : measure.date) + ':00',
            KW: parseFloat(measure.Power / 1000).toFixed(2),
        };
    });

    const { plants } = usePlants();

    const { data: plantNotes, loading: plantNotesLoading } = useQuery(getPlantNotesQuery, {
        variables: {
            plantId: plantId,
        },
    });

    const plantData = plants.find((plant) => plant.id === plantId);

    const [editPlantOpened, { open: openEditPlant, close: closeEditPlant }] = useDisclosure(false);
    const [downloadReportOpened, { open: openDownloadReport, close: closeDownloadReport }] = useDisclosure(false);

    return (
        <Tabs defaultValue="information">
            <StandardLoadingOverlay visible={plantNotesLoading} />
            <Group p="md" pt={0} justify="space-between">
                <Tabs.List>
                    <Tabs.Tab value="information">Informazioni</Tabs.Tab>
                    <Tabs.Tab value="notes">Note</Tabs.Tab>
                </Tabs.List>
                <Button.Group>
                    <Tooltip withArrow label="Modifica impostazioni impianto">
                        <Button onClick={openEditPlant} leftSection={<IconEdit size="1rem" />} size="compact-sm">
                            Modifica
                        </Button>
                    </Tooltip>
                    <Button onClick={openDownloadReport} size="compact-sm" leftSection={<IconDownload size="1rem" />}>
                        Download
                    </Button>
                    <Button
                        size="compact-sm"
                        color="red"
                        variant="outline"
                        onClick={() => {
                            modals.openConfirmModal({
                                title: 'Rimuovi impianto',
                                centered: true,
                                children: (
                                    <Text size="sm">
                                        Sei sicuro di voler rimuovere l'impianto {plantData.name}? L'operazione è
                                        irreversibile.
                                    </Text>
                                ),
                                labels: {
                                    confirm: 'Rimuovi impianto',
                                    cancel: 'Annulla',
                                },
                                confirmProps: { color: 'red' },
                                onConfirm: () => {
                                    deletePlant({ variables: { id: plantId } });
                                    modals.closeAll();
                                },
                            });
                        }}
                    >
                        <IconTrash size="1rem" />
                    </Button>
                </Button.Group>
            </Group>
            {Boolean(plantNotes) && Boolean(plantData) && (
                <>
                    <Tabs.Panel value="information">
                        <PlantInfoModalContent
                            displayMeasures={displayMeasures}
                            plantData={plantData}
                            activePlantNotes={plantNotes.plantNote.filter((note) => note.active)}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="notes">
                        <PlantNoteModalContent
                            plantId={plantId}
                            plantNotes={plantNotes.plantNote}
                            plantName={plantData.name}
                        />
                    </Tabs.Panel>
                </>
            )}
            {editPlantOpened && <EditPlantModal data={plantData} opened={editPlantOpened} close={closeEditPlant} />}
            {downloadReportOpened && Boolean(plantId) && (
                <ReportYearSelectModal plantId={plantId} opened={downloadReportOpened} close={closeDownloadReport} />
            )}
        </Tabs>
    );
};

export default PlantModalContent;
