import { usePlants } from '@/lib/store';
import { isNotEmpty, useForm } from '@mantine/form';

import { PlantSelectForm } from '../forms';

const PlantSelectStep = ({ formData, completeStepHandler }) => {
    const { scintillaPlants, plants } = usePlants();

    const form = useForm({
        initialValues: {
            selectedPlant: formData.selectedPlant?.id,
        },
        validate: {
            selectedPlant: isNotEmpty('Seleziona una centrale valida.'),
        },
    });

    return (
        <PlantSelectForm
            data={scintillaPlants
                .filter((scintillaPlant) => !plants.map((plant) => plant.extId).includes(scintillaPlant.id))
                .map((plant) => {
                    return { value: plant.id.toString(), label: plant.name };
                })}
            form={form}
            onSubmit={form.onSubmit((values) => {
                completeStepHandler(1, {
                    selectedPlant: {
                        id: values.selectedPlant,
                        name: scintillaPlants.find((plant) => plant.id === parseInt(values.selectedPlant)).name,
                        plantPower: scintillaPlants.find((plant) => plant.id === parseInt(values.selectedPlant)).kwimp,
                    },
                });
            })}
        />
    );
};

export default PlantSelectStep;
