import { gql } from '@apollo/client';

const getPlantsQuery = gql`
    query getPlants($date: timestamp!) {
        plant(order_by: { name: asc }) {
            id
            name
            url
            extId: ext_id
            refreshInterval: refresh_interval
            details
            maintenance
            repairing
            active
            communicating
            temperatureCoefficient: temperature_coefficient
            tare
            solarimeter
            plantPower: plant_power
            targetPerformanceRatio: target_performance_ratio
            apiType: api_type {
                id
                name
            }
            sendAlertNotification: send_alert_notification
            measures(where: { date: { _gte: $date } }, order_by: { date: desc }, limit: 1) {
                alarm
                date
                irradiance
                power
                solarimeter
                temperature
                errorType: error_type {
                    name
                }
            }
            plantNotes: plant_notes(where: { active: { _eq: true } }) {
                content
                createdDate: created_date
                email
            }
        }
    }
`;

export default getPlantsQuery;
