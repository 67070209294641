import { gql } from '@apollo/client';

const getUserQuery = gql`
    query getUser {
        user {
            email
        }
    }
`;

export default getUserQuery;
