import { gql } from '@apollo/client';

const insertPlantMutation = gql`
    mutation insertPlant(
        $active: Boolean!
        $apiTypeId: Int!
        $details: String!
        $extId: Int!
        $maintenance: Boolean!
        $name: String!
        $refreshInterval: Int!
        $repairing: Boolean!
        $solarimeter: Boolean!
        $sendAlertNotification: Boolean!
        $tare: float8!
        $targetPerformanceRatio: float8!
        $temperatureCoefficient: float8!
        $url: String!
        $plantPower: float8!
    ) {
        insert_plant_one(
            object: {
                active: $active
                api_type_id: $apiTypeId
                details: $details
                ext_id: $extId
                maintenance: $maintenance
                name: $name
                plant_power: $plantPower
                refresh_interval: $refreshInterval
                repairing: $repairing
                solarimeter: $solarimeter
                tare: $tare
                target_performance_ratio: $targetPerformanceRatio
                temperature_coefficient: $temperatureCoefficient
                send_alert_notification: $sendAlertNotification
                url: $url
            }
        ) {
            id
        }
    }
`;

export default insertPlantMutation;
