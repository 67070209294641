import { ActionIcon, Menu } from '@mantine/core';
import { IconLogout, IconSettings } from '@tabler/icons-react';
import { signOut, useSession } from 'next-auth/react';

const HeaderMenu = () => {
    const { data: session } = useSession();
    return (
        <Menu shadow="md" withArrow>
            <Menu.Target>
                <ActionIcon component="a" size="md">
                    <IconSettings size="1.4rem" />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label>Ciao, {session.data.email}</Menu.Label>
                <Menu.Item leftSection={<IconLogout size="1.2rem" />} onClick={async () => await signOut()}>
                    Logout
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};

export default HeaderMenu;
