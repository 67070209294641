import { Box, Button, Select } from '@mantine/core';
import { IconArrowRight, IconSolarPanel2 } from '@tabler/icons-react';

const PlantSelectForm = ({ onSubmit, form, data }) => {
    return (
        <Box p="md" component="form" onSubmit={onSubmit}>
            <Select
                label="Importa impianto Spark"
                description="Seleziona un impianto su Spark da importare"
                data={data}
                leftSection={<IconSolarPanel2 size="1.2rem" />}
                searchable
                placeholder="Seleziona impianto da Spark"
                {...form.getInputProps('selectedPlant')}
            />
            <Button type="submit" size="compact-md" mt="md" leftSection={<IconArrowRight />}>
                Avanti
            </Button>
        </Box>
    );
};

export default PlantSelectForm;
