import { Center, Loader, Overlay } from '@mantine/core';

const StandardLoader = () => {
    return (
        <Overlay color="#000" backgroundOpacity={0.2}>
            <Center h="100vh" mx="auto">
                <Loader size="xl" />
            </Center>
        </Overlay>
    );
};

export default StandardLoader;
